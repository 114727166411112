/*--------------------------------

SugarIcons icon font
Generated using nucleoapp.com

-------------------------------- */

@font-face {
  font-family: 'SugarIcons';
  src: url('../fonts/SugarIcons.eot');
  src: url('../fonts/SugarIcons.eot') format('embedded-opentype'),
       url('../fonts/SugarIcons.woff2') format('woff2'),
       url('../fonts/SugarIcons.woff') format('woff'),
       url('../fonts/SugarIcons.ttf') format('truetype'),
       url('../fonts/SugarIcons.svg') format('svg');
}

/* base class */
.sicon {
  display: inline-block;
  font: normal normal normal 1em/1 'SugarIcons';
  color: inherit;
  flex-shrink: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* size examples - relative units */
.sicon-sm {
  font-size: 0.8em;
}

.sicon-lg {
  font-size: 1.2em;
}

/* size examples - absolute units */
.sicon-16 {
  font-size: 16px;
}

.sicon-32 {
  font-size: 32px;
}

/* rotate the icon infinitely */
.sicon-is-spinning {
  animation: sicon-spin 1s infinite linear;
}

@keyframes sicon-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* transform */
.sicon-rotate-90 {
  transform: rotate(90deg);
}

.sicon-rotate-180 {
  transform: rotate(180deg);
}

.sicon-rotate-270 {
  transform: rotate(270deg);
}

.sicon-flip-y {
  transform: scaleY(-1);
}

.sicon-flip-x {
  transform: scaleX(-1);
}

/* icons */
.sicon-arrow-down::before {
  content: "\ea03";
}

.sicon-chevron-left::before {
  content: "\ea05";
}

.sicon-check::before {
  content: "\ea07";
}

.sicon-clock::before {
  content: "\ea08";
}

.sicon-dashboard-default::before {
  content: "\ea09";
}

.sicon-dashboard::before {
  content: "\ea0a";
}

.sicon-edit::before {
  content: "\ea0b";
}

.sicon-caret-down::before {
  content: "\ea0c";
}

.sicon-info::before {
  content: "\ea0e";
}

.sicon-kebab::before {
  content: "\ea0f";
}

.sicon-link::before {
  content: "\ea10";
}

.sicon-list::before {
  content: "\ea11";
}

.sicon-logout::before {
  content: "\ea12";
}

.sicon-minus::before {
  content: "\ea13";
}

.sicon-plus-sm::before {
  content: "\ea15";
}

.sicon-refresh::before {
  content: "\ea16";
}

.sicon-plus::before {
  content: "\ea17";
}

.sicon-settings::before {
  content: "\ea19";
}

.sicon-arrow-right-double::before {
  content: "\ea1a";
}

.sicon-reports::before {
  content: "\ea1b";
}

.sicon-user::before {
  content: "\ea1c";
}

.sicon-upload::before {
  content: "\ea1d";
}

.sicon-arrow-left-double::before {
  content: "\ea1f";
}

.sicon-chevron-up::before {
  content: "\ea20";
}

.sicon-caret-up::before {
  content: "\ea22";
}

.sicon-star-fill::before {
  content: "\ea23";
}

.sicon-download::before {
  content: "\ea24";
}

.sicon-close::before {
  content: "\ea25";
}

.sicon-tile-view::before {
  content: "\ea26";
}

.sicon-list-view::before {
  content: "\ea27";
}

.sicon-thumbs-down::before {
  content: "\ea28";
}

.sicon-warning-circle::before {
  content: "\ea29";
}

.sicon-phone::before {
  content: "\ea2a";
}

.sicon-email::before {
  content: "\ea2b";
}

.sicon-document::before {
  content: "\ea2c";
}

.sicon-note::before {
  content: "\ea2d";
}

.sicon-preview::before {
  content: "\ea2e";
}

.sicon-launch::before {
  content: "\ea30";
}

.sicon-lock::before {
  content: "\ea32";
}

.sicon-arrow-top-right::before {
  content: "\ea33";
}

.sicon-full-screen::before {
  content: "\ea34";
}

.sicon-full-screen-exit::before {
  content: "\ea35";
}

.sicon-expand-left::before {
  content: "\ea36";
}

.sicon-expand-right::before {
  content: "\ea37";
}

.sicon-focus-drawer::before {
  content: "\ea38";
}

.sicon-ban::before {
  content: "\ea39";
}

.sicon-thumbs-up::before {
  content: "\ea3a";
}

.sicon-search::before {
  content: "\ea3b";
}

.sicon-calendar::before {
  content: "\ea3c";
}

.sicon-calendar-lg::before {
  content: "\ea3d";
}

.sicon-mobile-lg::before {
  content: "\ea3e";
}

.sicon-star-fill-lg::before {
  content: "\ea3f";
}

.sicon-star-outline-lg::before {
  content: "\ea40";
}

.sicon-reset-lg::before {
  content: "\ea41";
}

.sicon-exchange-lg::before {
  content: "\ea42";
}

.sicon-help-lg::before {
  content: "\ea43";
}

.sicon-close-lg::before {
  content: "\ea44";
}

.sicon-plus-lg::before {
  content: "\ea45";
}

.sicon-shortcuts-lg::before {
  content: "\ea46";
}

.sicon-search-lg::before {
  content: "\ea47";
}

.sicon-email-lg::before {
  content: "\ea49";
}

.sicon-note-lg::before {
  content: "\ea4a";
}

.sicon-add-dashlet-lg::before {
  content: "\ea4c";
}

.sicon-collapse-lg::before {
  content: "\ea4d";
}

.sicon-hamburger-lg::before {
  content: "\ea4e";
}

.sicon-pin-fill-lg::before {
  content: "\ea4f";
}

.sicon-expand-lg::before {
  content: "\ea50";
}

.sicon-dashboard-lg::before {
  content: "\ea52";
}

.sicon-star-outline::before {
  content: "\ea54";
}

.sicon-reset::before {
  content: "\ea55";
}

.sicon-unlink::before {
  content: "\ea56";
}

.sicon-phone-lg::before {
  content: "\ea57";
}

.sicon-attach::before {
  content: "\ea59";
}

.sicon-case-lg::before {
  content: "\ea5a";
}

.sicon-arrow-right-lg::before {
  content: "\ea5b";
}

.sicon-check-circle-lg::before {
  content: "\ea5c";
}

.sicon-text-formatting::before {
  content: "\ea5d";
}

.sicon-check-circle::before {
  content: "\ea5e";
}

.sicon-upload-lg::before {
  content: "\ea5f";
}

.sicon-flag-lg::before {
  content: "\ea60";
}

.sicon-studio-lg::before {
  content: "\ea63";
}

.sicon-contact-lg::before {
  content: "\ea65";
}

.sicon-edit-lg::before {
  content: "\ea66";
}

.sicon-arrow-left::before {
  content: "\ea67";
}

.sicon-reply-all::before {
  content: "\ea69";
}

.sicon-message-lg::before {
  content: "\ea6a";
}

.sicon-info-lg::before {
  content: "\ea6b";
}

.sicon-error-lg::before {
  content: "\ea6c";
}

.sicon-check-circle-line-lg::before {
  content: "\ea6d";
}

.sicon-warning-line-lg::before {
  content: "\ea6e";
}

.sicon-arrow-up::before {
  content: "\ea6f";
}

.sicon-arrow-right::before {
  content: "\ea70";
}

.sicon-nodes::before {
  content: "\ea71";
}

.sicon-hide::before {
  content: "\ea72";
}

.sicon-help::before {
  content: "\ea73";
}

.sicon-twitter::before {
  content: "\ea74";
}

.sicon-message::before {
  content: "\ea75";
}

.sicon-sugar-predict-lg::before {
  content: "\ea78";
}

.sicon-sugar-predict::before {
  content: "\ea7b";
}

.sicon-opportunity-lg::before {
  content: "\ea7d";
}

.sicon-lead-lg::before {
  content: "\ea7e";
}

.sicon-account-lg::before {
  content: "\ea7f";
}

.sicon-user-sm::before {
  content: "\ea80";
}

.sicon-quote-lg::before {
  content: "\ea81";
}

.sicon-equal::before {
  content: "\ea94";
}

.sicon-slicers-xl::before {
  content: "\ea95";
}

.sicon-escalation-lg::before {
  content: "\ea96";
}

.sicon-add-circle-lg::before {
  content: "\ea97";
}

.sicon-add-line-lg::before {
  content: "\ea98";
}

.sicon-add-target-lg::before {
  content: "\ea99";
}

.sicon-help-circle-lg::before {
  content: "\ea9a";
}

.sicon-divide-line-lg::before {
  content: "\ea9b";
}

.sicon-string::before {
  content: "\ea9c";
}

.sicon-filter::before {
  content: "\ea9d";
}

.sicon-number::before {
  content: "\ea9e";
}

.sicon-format-lg::before {
  content: "\ea9f";
}

.sicon-insights-lg::before {
  content: "\eaa0";
}

.sicon-invalid-circle-lg::before {
  content: "\eaa1";
}

.sicon-discover-xl::before {
  content: "\eaa2";
}

.sicon-layout-lg::before {
  content: "\eaa3";
}

.sicon-multiply-line-lg::before {
  content: "\eaa4";
}

.sicon-redo-lg::before {
  content: "\eaa7";
}

.sicon-refresh-lg::before {
  content: "\eaa8";
}

.sicon-undo-lg::before {
  content: "\eaac";
}

.sicon-activity-feed-xl::before {
  content: "\eaad";
}

.sicon-chevron-left-xl::before {
  content: "\eaae";
}

.sicon-close-xl::before {
  content: "\eaaf";
}

.sicon-kebab-lg::before {
  content: "\eab1";
}

.sicon-insights-xl::before {
  content: "\eab3";
}

.sicon-hide-lg::before {
  content: "\eab4";
}

.sicon-kebab-xl::before {
  content: "\eab5";
}

.sicon-comment-xl::before {
  content: "\eab8";
}

.sicon-share-lg::before {
  content: "\eab9";
}

.sicon-trash::before {
  content: "\eaba";
}

.sicon-launch-lg::before {
  content: "\eabb";
}

.sicon-thumbs-up-lg::before {
  content: "\eabf";
}

.sicon-thumbs-down-lg::before {
  content: "\eac0";
}

.sicon-warning-lg::before {
  content: "\eac1";
}

.sicon-wizard-circle-xl::before {
  content: "\eac2";
}

.sicon-bell-cross-lg::before {
  content: "\eac4";
}

.sicon-bell-lg::before {
  content: "\eac5";
}

.sicon-daily-lg::before {
  content: "\eac6";
}

.sicon-cloud-download-lg::before {
  content: "\eac7";
}

.sicon-unlock::before {
  content: "\eac8";
}

.sicon-news-lg::before {
  content: "\eac9";
}

.sicon-weekly-lg::before {
  content: "\eaca";
}

.sicon-knowledgebase-xl::before {
  content: "\eacb";
}

.sicon-remove::before {
  content: "\eacc";
}

.sicon-chevron-down::before {
  content: "\eacd";
}

.sicon-slicers-lg::before {
  content: "\eace";
}

.sicon-save-lg::before {
  content: "\eacf";
}

.sicon-info-circle-lg::before {
  content: "\ead0";
}

.sicon-chevron-right::before {
  content: "\ead1";
}

.sicon-warning-circle-lg::before {
  content: "\ead2";
}

.sicon-right-paren-line-lg::before {
  content: "\ead3";
}

.sicon-comment-lg::before {
  content: "\ead4";
}

.sicon-target-lg::before {
  content: "\ead5";
}

.sicon-subtract-line-lg::before {
  content: "\ead6";
}

.sicon-settings-lg::before {
  content: "\ead7";
}

.sicon-left-paren-line-lg::before {
  content: "\ead8";
}

.sicon-preview-lg::before {
  content: "\ead9";
}

.sicon-thumbs-down-line-lg::before {
  content: "\eada";
}

.sicon-thumbs-up-line-lg::before {
  content: "\eadb";
}

.sicon-send-lg::before {
  content: "\eadc";
}

.sicon-contact-xl::before {
  content: "\eadd";
}

.sicon-wizard-circle-line-xl::before {
  content: "\eade";
}

.sicon-trash-lg::before {
  content: "\eadf";
}

.sicon-pin-lg::before {
  content: "\eae0";
}

.sicon-clock-lg::before {
  content: "\eae1";
}

.sicon-comment-line-xl::before {
  content: "\eae2";
}

.sicon-dropdown-editor::before {
  content: "\eae5";
}

.sicon-amazon::before {
  content: "\eae6";
}

.sicon-bug::before {
  content: "\eae7";
}

.sicon-catalog::before {
  content: "\eae8";
}

.sicon-cloud::before {
  content: "\eae9";
}

.sicon-config-api::before {
  content: "\eaea";
}

.sicon-config-nav::before {
  content: "\eaeb";
}

.sicon-connectors::before {
  content: "\eaec";
}

.sicon-contracts::before {
  content: "\eaed";
}

.sicon-currencies::before {
  content: "\eaee";
}

.sicon-diagnostics::before {
  content: "\eaef";
}

.sicon-sugar-club::before {
  content: "\eaf0";
}

.sicon-email-campaign::before {
  content: "\eaf1";
}

.sicon-email-inbound::before {
  content: "\eaf2";
}

.sicon-email-queue::before {
  content: "\eaf3";
}

.sicon-forecasts::before {
  content: "\eaf4";
}

.sicon-display-modules::before {
  content: "\eaf5";
}

.sicon-email-contacts::before {
  content: "\eaf6";
}

.sicon-import::before {
  content: "\eaf7";
}

.sicon-log-viewer::before {
  content: "\eaf8";
}

.sicon-lab::before {
  content: "\eaf9";
}

.sicon-map-pin::before {
  content: "\eafa";
}

.sicon-manufacturers::before {
  content: "\eafb";
}

.sicon-update::before {
  content: "\eafc";
}

.sicon-content-security::before {
  content: "\eafd";
}

.sicon-module-builder::before {
  content: "\eafe";
}

.sicon-languages::before {
  content: "\eaff";
}

.sicon-module-loader::before {
  content: "\eb00";
}

.sicon-oauth-key::before {
  content: "\eb01";
}

.sicon-password-mgmt::before {
  content: "\eb02";
}

.sicon-opportunities::before {
  content: "\eb03";
}

.sicon-upgrade::before {
  content: "\eb04";
}

.sicon-portal::before {
  content: "\eb05";
}

.sicon-product-types::before {
  content: "\eb06";
}

.sicon-quotes::before {
  content: "\eb07";
}

.sicon-relate-fields::before {
  content: "\eb08";
}

.sicon-repair::before {
  content: "\eb09";
}

.sicon-role-mgmt::before {
  content: "\eb0a";
}

.sicon-scheduler::before {
  content: "\eb0b";
}

.sicon-shippers::before {
  content: "\eb0c";
}

.sicon-studio::before {
  content: "\eb0d";
}

.sicon-web-logic::before {
  content: "\eb0e";
}

.sicon-sugar-u::before {
  content: "\eb0f";
}

.sicon-tax-rates::before {
  content: "\eb10";
}

.sicon-team-mgmt::before {
  content: "\eb11";
}

.sicon-team-perm::before {
  content: "\eb12";
}

.sicon-tracker::before {
  content: "\eb13";
}

.sicon-workflow::before {
  content: "\eb15";
}

.sicon-mask::before {
  content: "\eb16";
}

.sicon-mask-disabled::before {
  content: "\eb17";
}

.sicon-email-archive::before {
  content: "\eb18";
}

.sicon-mobile::before {
  content: "\eb19";
}

.sicon-archive::before {
  content: "\eb1a";
}

.sicon-pdf-manager::before {
  content: "\eb1c";
}

.sicon-my-sites::before {
  content: "\eb1d";
}

.sicon-cloud-insights::before {
  content: "\eb1e";
}

.sicon-sugar-logo-24::before {
  content: "\eb1f";
}

.sicon-sugar-logo-16::before {
  content: "\eb20";
}

.sicon-task-lg::before {
  content: "\eb22";
}

.sicon-web-lg::before {
  content: "\eb23";
}

.sicon-events-lg::before {
  content: "\eb24";
}

.sicon-sugar-logo-12::before {
  content: "\eb25";
}

.sicon-tag::before {
  content: "\eb27";
}

.sicon-tag-lg::before {
  content: "\eb28";
}

.sicon-bell-lg-line::before {
  content: "\eb3d";
}

.sicon-matrix-xxl::before {
  content: "\eb3e";
}

.sicon-advanced-filter::before {
  content: "\eb3f";
}

.sicon-rows-and-columns-xxl::before {
  content: "\eb40";
}

.sicon-summation-details-xxl::before {
  content: "\eb41";
}

.sicon-summation-xxl::before {
  content: "\eb42";
}

.sicon-lock-lg::before {
  content: "\eb43";
}

.sicon-csv-file-xxl::before {
  content: "\eb44";
}

.sicon-logo-linkedin::before {
  content: "\eb45";
}

.sicon-briefcase::before {
  content: "\eb46";
}

.sicon-car-front::before {
  content: "\eb47";
}

.sicon-airplane::before {
  content: "\eb48";
}

.sicon-laptop::before {
  content: "\eb49";
}

.sicon-logo-instagram::before {
  content: "\eb4a";
}

.sicon-single-bed::before {
  content: "\eb4b";
}

.sicon-tiktok::before {
  content: "\eb4d";
}

.sicon-logo-snapchat::before {
  content: "\eb4e";
}

.sicon-video-lg::before {
  content: "\eb4f";
}

.sicon-reply::before {
  content: "\eb50";
}

.sicon-subtract::before {
  content: "\eb51";
}

.sicon-arrow-up-circle-line::before {
  content: "\eb52";
}

.sicon-asterisk::before {
  content: "\eb53";
}

.sicon-dot-circle-o-lg::before {
  content: "\eb54";
}

.sicon-forward::before {
  content: "\eb55";
}

.sicon-video::before {
  content: "\eb56";
}

.sicon-gavel::before {
  content: "\eb57";
}

.sicon-login::before {
  content: "\eb58";
}

.sicon-pdf-file-xxl::before {
  content: "\eb5c";
}

.sicon-login-lg::before {
  content: "\eb5d";
}

.sicon-cloud-download-xl::before {
  content: "\eb5e";
}

.sicon-campaigns-lg::before {
  content: "\eb5f";
}

.sicon-customer-journey-stages-lg::before {
  content: "\eb60";
}

.sicon-role-mgmt-lg::before {
  content: "\eb61";
}

.sicon-default-module-lg::before {
  content: "\eb62";
}

.sicon-quoted-line-items-lg::before {
  content: "\eb63";
}

.sicon-projects-lg::before {
  content: "\eb64";
}

.sicon-process-business-rules-lg::before {
  content: "\eb65";
}

.sicon-process-email-templates-lg::before {
  content: "\eb66";
}

.sicon-processes-lg::before {
  content: "\eb67";
}

.sicon-process-definitions-lg::before {
  content: "\eb68";
}

.sicon-target-lists-lg::before {
  content: "\eb69";
}

.sicon-purchased-line-items-lg::before {
  content: "\eb6a";
}

.sicon-revenue-line-items-lg::before {
  content: "\eb6c";
}

.sicon-business-centers-lg::before {
  content: "\eb6d";
}

.sicon-logo-fb-simple::before {
  content: "\eb6f";
}

.sicon-forecasts-lg::before {
  content: "\eb71";
}

.sicon-purchase-lg::before {
  content: "\eb75";
}

.sicon-sugar-live-lg::before {
  content: "\eb76";
}

.sicon-customer-journey-lg::before {
  content: "\eb77";
}

.sicon-data-privacy-lg::before {
  content: "\eb78";
}

.sicon-home-lg::before {
  content: "\eb7a";
}

.sicon-data-table::before {
  content: "\eb7b";
}

.sicon-medical-clipboard-lg::before {
  content: "\eb7e";
}

.sicon-privacy-lg::before {
  content: "\eb7f";
}

.sicon-marketplace::before {
  content: "\eb83";
}

.sicon-marketplace-lg::before {
  content: "\eb84";
}

.sicon-blockquote-lg::before {
  content: "\eb85";
}

.sicon-casino-lg::before {
  content: "\eb86";
}

.sicon-jacuzzi-lg::before {
  content: "\eb87";
}

.sicon-scissors-lg::before {
  content: "\eb88";
}

.sicon-storage-lg::before {
  content: "\eb89";
}

.sicon-fan-lg::before {
  content: "\eb8a";
}

.sicon-medicine-lg::before {
  content: "\eb8b";
}

.sicon-plant-lg::before {
  content: "\eb8c";
}

.sicon-telescope-lg::before {
  content: "\eb8d";
}

.sicon-smart-phone-lg::before {
  content: "\eb8e";
}

.sicon-coffee-lg::before {
  content: "\eb8f";
}

.sicon-martini-lg::before {
  content: "\eb90";
}

.sicon-cocktail-lg::before {
  content: "\eb91";
}

.sicon-stadium-lg::before {
  content: "\eb92";
}

.sicon-fire-lg::before {
  content: "\eb93";
}

.sicon-window-lg::before {
  content: "\eb94";
}

.sicon-lotus-lg::before {
  content: "\eb95";
}

.sicon-windmill-lg::before {
  content: "\eb96";
}

.sicon-spades-lg::before {
  content: "\eb97";
}

.sicon-hearts-lg::before {
  content: "\eb98";
}

.sicon-diamonds-lg::before {
  content: "\eb99";
}

.sicon-clubs-lg::before {
  content: "\eb9a";
}

.sicon-skull-lg::before {
  content: "\eb9b";
}

.sicon-send::before {
  content: "\eb9c";
}

.sicon-world-lg::before {
  content: "\eb9d";
}

.sicon-airplane-lg::before {
  content: "\eb9e";
}

.sicon-planet-lg::before {
  content: "\eb9f";
}

.sicon-trainer-lg::before {
  content: "\eba0";
}

.sicon-calculator-lg::before {
  content: "\eba1";
}

.sicon-tv-lg::before {
  content: "\eba2";
}

.sicon-video-player-lg::before {
  content: "\eba3";
}

.sicon-media-player-lg::before {
  content: "\eba4";
}

.sicon-decentralize-lg::before {
  content: "\eba5";
}

.sicon-statistics-lg::before {
  content: "\eba6";
}

.sicon-virus-lg::before {
  content: "\eba7";
}

.sicon-shield-lg::before {
  content: "\eba8";
}

.sicon-plug-lg::before {
  content: "\eba9";
}

.sicon-shuttle-lg::before {
  content: "\ebaa";
}

.sicon-soundwave-lg::before {
  content: "\ebab";
}

.sicon-graph-lg::before {
  content: "\ebac";
}

.sicon-playlist-lg::before {
  content: "\ebad";
}

.sicon-voice-recognition-lg::before {
  content: "\ebae";
}

.sicon-machine-learning-lg::before {
  content: "\ebaf";
}

.sicon-bed-lg::before {
  content: "\ebb0";
}

.sicon-blueprint-lg::before {
  content: "\ebb1";
}

.sicon-content-delivery-lg::before {
  content: "\ebb2";
}

.sicon-questionnaire-lg::before {
  content: "\ebb3";
}

.sicon-presentation-lg::before {
  content: "\ebb4";
}

.sicon-organic-lg::before {
  content: "\ebb5";
}

.sicon-transactions-lg::before {
  content: "\ebb6";
}

.sicon-wand-lg::before {
  content: "\ebb7";
}

.sicon-bin-lg::before {
  content: "\ebb8";
}

.sicon-contracts-lg::before {
  content: "\ebb9";
}

.sicon-meetings-lg::before {
  content: "\ebbf";
}

.sicon-external-authentication-lg::before {
  content: "\ebc0";
}

.sicon-trophy::before {
  content: "\ebc1";
}

.sicon-sync-lg::before {
  content: "\ebc2";
}

.sicon-account::before {
  content: "\ebc3";
}

.sicon-tracking-activity-lg::before {
  content: "\ebc4";
}

.sicon-grid-lg::before {
  content: "\ebc5";
}

.sicon-google-lg::before {
  content: "\ebc6";
}

.sicon-refresh-1::before {
  content: "\ebc7";
}

.sicon-entries::before {
  content: "\ebc8";
}

.sicon-target-email-lg::before {
  content: "\ebc9";
}

.sicon-chevron-up-lg::before {
  content: "\ebca";
}

.sicon-chevron-left-lg::before {
  content: "\ebcb";
}

.sicon-chevron-right-lg::before {
  content: "\ebcc";
}

.sicon-chevron-down-lg::before {
  content: "\ebcd";
}

.sicon-database-lg::before {
  content: "\ebce";
}

.sicon-check-lg::before {
  content: "\ebd1";
}

.sicon-folder-open::before {
  content: "\ebd2";
}

.sicon-folder::before {
  content: "\ebd3";
}

.sicon-folder-line::before {
  content: "\ebd4";
}

.sicon-vertical-split::before {
  content: "\ebd6";
}

.sicon-horizontal-split::before {
  content: "\ebd9";
}

.sicon-user-lg::before {
  content: "\ebda";
}

.sicon-clicks-lg::before {
  content: "\ebdb";
}

.sicon-reply-lg::before {
  content: "\ebdc";
}

.sicon-door-lg::before {
  content: "\ebdd";
}

.sicon-dollar-bill-lg::before {
  content: "\ebde";
}

.sicon-yen-lg::before {
  content: "\ebdf";
}

.sicon-credit-card-lg::before {
  content: "\ebe0";
}

.sicon-bitcoin-lg::before {
  content: "\ebe1";
}

.sicon-pound-lg::before {
  content: "\ebe2";
}

.sicon-money-bag-lg::before {
  content: "\ebe3";
}

.sicon-cents-lg::before {
  content: "\ebe4";
}

.sicon-dollar-lg::before {
  content: "\ebe5";
}

.sicon-euro-lg::before {
  content: "\ebe6";
}

.sicon-logout-lg::before {
  content: "\ebe7";
}

.sicon-archive-lg::before {
  content: "\ebe8";
}

.sicon-sidebyside::before {
  content: "\ebe9";
}

.sicon-stacked::before {
  content: "\ebea";
}

.sicon-file-upload::before {
  content: "\ebee";
}

.sicon-add-folder::before {
  content: "\ebef";
}

.sicon-route::before {
  content: "\ebf0";
}

.sicon-timeline::before {
  content: "\ebf1";
}

.sicon-all-fields-xxl::before {
  content: "\ebf5";
}

.sicon-automate-stacked::before {
  content: "\ebf7";
}

.sicon-automate-scroll::before {
  content: "\ebf8";
}

.sicon-copy-xl::before {
  content: "\ebf9";
}

.sicon-copy::before {
  content: "\ebfa";
}

.sicon-copy-lg::before {
  content: "\ebfb";
}

.sicon-info-fill-xl-32::before {
  content: "\ebfc";
}

.sicon-warning-circle-xl-32::before {
  content: "\ebfd";
}

.sicon-sort-fields-xxl::before {
  content: "\ec02";
}

.sicon-swap::before {
  content: "\ec03";
}

.sicon-tile-view-2::before {
  content: "\ec04";
}

.sicon-check-circle-xl-32::before {
  content: "\ec12";
}

.sicon-edit-xl::before {
  content: "\ec13";
}

.sicon-trash-xl::before {
  content: "\ec14";
}

.sicon-copy-meeting-xl::before {
  content: "\ec15";
}

.sicon-copy-calendar-link-xl::before {
  content: "\ec16";
}

.sicon-warning-xl-32::before {
  content: "\ec18";
}

.sicon-inbox::before {
  content: "\ec19";
}

.sicon-save::before {
  content: "\ec1a";
}

.sicon-play::before {
  content: "\ec1b";
}

.sicon-end-event::before {
  content: "\ec1c";
}

.sicon-linkedin::before {
  content: "\ec1d";
}

.sicon-warning-triangle::before {
  content: "\ec1e";
}

.sicon-validate::before {
  content: "\ec1f";
}

.sicon-redo::before {
  content: "\ec20";
}

.sicon-activity::before {
  content: "\ec21";
}

.sicon-ellipsis::before {
  content: "\ec22";
}

.sicon-exclusive::before {
  content: "\ec25";
}

.sicon-switch-name::before {
  content: "\ec26";
}

.sicon-currency::before {
  content: "\ec5b";
}

.sicon-varchar::before {
  content: "\ec5c";
}

.sicon-autoincrement::before {
  content: "\ec5d";
}

.sicon-url::before {
  content: "\ec5e";
}

.sicon-bool::before {
  content: "\ec5f";
}

.sicon-text::before {
  content: "\ec60";
}

.sicon-relate::before {
  content: "\ec61";
}

.sicon-radioenum::before {
  content: "\ec62";
}

.sicon-phonefill::before {
  content: "\ec63";
}

.sicon-multienum::before {
  content: "\ec64";
}

.sicon-int::before {
  content: "\ec65";
}

.sicon-image::before {
  content: "\ec66";
}

.sicon-iframe::before {
  content: "\ec67";
}

.sicon-html::before {
  content: "\ec68";
}

.sicon-parent::before {
  content: "\ec6a";
}

.sicon-fields::before {
  content: "\ec6b";
}

.sicon-encrypt::before {
  content: "\ec6c";
}

.sicon-enum::before {
  content: "\ec6d";
}

.sicon-decimal::before {
  content: "\ec6e";
}

.sicon-datetime::before {
  content: "\ec6f";
}

.sicon-date::before {
  content: "\ec70";
}

.sicon-address::before {
  content: "\ec71";
}

.sicon-actionbutton::before {
  content: "\ec72";
}

.sicon-float::before {
  content: "\ec74";
}

.sicon-remove-field-hover::before {
  content: "\ec7b";
}

.sicon-remove-field::before {
  content: "\ec7c";
}

.sicon-datetimecombo::before {
  content: "\ec7d";
}

.sicon-name::before {
  content: "\ec7e";
}

.sicon-features-lg::before {
  content: "\ec7f";
}

.sicon-archive-box-lg::before {
  content: "\ea01";
}
